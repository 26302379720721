var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('ControlNavbar'),_c('page-header',{attrs:{"title":_vm.transfer.label}}),_c('div',{staticClass:"container"},[(_vm.transfer.banner)?_c('div',{staticClass:"banner"},[_c('Banner',{attrs:{"banner":_vm.transfer.banner}})],1):_vm._e(),_c('br'),(_vm.transfer)?_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[(_vm.transfer.data.countries)?_c('a-form-item',{attrs:{"label":_vm.locale.country_code}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'countryCode',
            {
              initialValue: _vm.transfer.data.defaultCountry,
              rules: [
                { required: true, messasge: _vm.locale.emptyFieldsWarning } ],
            } ]),expression:"[\n            'countryCode',\n            {\n              initialValue: transfer.data.defaultCountry,\n              rules: [\n                { required: true, messasge: locale.emptyFieldsWarning },\n              ],\n            },\n          ]"}]},_vm._l((_vm.transfer.data.countries),function(country){return _c('a-select-option',{key:country,attrs:{"value":country}},[_vm._v(" "+_vm._s(_vm.countries[country.toUpperCase()])+" "),_c('img',{attrs:{"src":require(("../assets/flags/" + country + ".png")),"width":"35px","alt":""}})])}),1)],1):_vm._e(),(
          _vm.transfer.data.inputType &&
            (_vm.transfer.data.inputType == 'text' ||
              _vm.transfer.data.inputType == 'email')
        )?_c('a-form-item',{attrs:{"label":_vm.transfer.data.hint}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'msisdn',
            {
              rules: [{ required: true, message: _vm.locale.emptyFieldsWarning }],
            } ]),expression:"[\n            'msisdn',\n            {\n              rules: [{ required: true, message: locale.emptyFieldsWarning }],\n            },\n          ]"}]})],1):(
          _vm.transfer.data.inputType &&
            (_vm.transfer.data.inputType == 'phone' ||
              _vm.transfer.data.inputType == 'number' ||
              _vm.transfer.data.inputType == 'numeric' ||
              _vm.transfer.data.inputType == 'decimal')
        )?_c('a-form-item',{attrs:{"label":_vm.transfer.data.hint}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            "msisdn",
            {
              rules: [
                {
                  required: true,
                  message: _vm.locale.emptyFieldsWarning,
                } ],
            } ]),expression:"[\n            `msisdn`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: locale.emptyFieldsWarning,\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"}})],1):_vm._e(),(_vm.transfer.data.amount)?_c('a-form-item',{attrs:{"label":_vm.transfer.data.amount.text}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            "amount",
            {
              rules: [
                {
                  required: true,
                  message: ((_vm.transfer.data.amount.text) + " " + (_vm.locale.must_be_between) + " " + (_vm.transfer.data.amount.min) + " " + (_vm.locale.and) + " " + (_vm.transfer.data.amount.max) + " "),
                },
                {
                  type: 'number',
                  max: _vm.transfer.data.amount.max,
                  min: _vm.transfer.data.amount.min,
                  message: ((_vm.transfer.data.amount.text) + " " + (_vm.locale.must_be_between) + " " + (_vm.transfer.data.amount.min) + " " + (_vm.locale.and) + " " + (_vm.transfer.data.amount.max) + " "),
                } ],
            } ]),expression:"[\n            `amount`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: `${transfer.data.amount.text} ${locale.must_be_between} ${transfer.data.amount.min} ${locale.and} ${transfer.data.amount.max} `,\n                },\n                {\n                  type: 'number',\n                  max: transfer.data.amount.max,\n                  min: transfer.data.amount.min,\n                  message: `${transfer.data.amount.text} ${locale.must_be_between} ${transfer.data.amount.min} ${locale.and} ${transfer.data.amount.max} `,\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":_vm.transfer.data.amount.min,"max":_vm.transfer.data.amount.max,"step":_vm.transfer.data.amount.increment,"formatter":function (value) { return ("" + value).replace(/\B(?=(\d{3})+(?!\d))/g, ','); }},on:{"change":function (e) {
              _vm.getTransferPrice(e);
            }}})],1):_vm._e(),(_vm.transfer.data.cost)?_c('a-form-item',{attrs:{"label":_vm.transfer.data.cost}},[_c('p',{attrs:{"id":"price"}},[_vm._v(" "+_vm._s(_vm.cost)+" ")])]):_vm._e(),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"danger","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.transfer.data.transfer)+" ")])],1)],1):_vm._e()],1),(_vm.apiCost)?_c('a-modal',{attrs:{"title":_vm.locale.paymentoptions,"footer":null},on:{"ok":function($event){_vm.paymentOptionsVisible = !_vm.paymentOptionsVisible}},model:{value:(_vm.paymentOptionsVisible),callback:function ($$v) {_vm.paymentOptionsVisible=$$v},expression:"paymentOptionsVisible"}},[(_vm.showPaymentOptions)?_c('div',_vm._l((_vm.$session.get('paymentOptions')),function(option){return _c('div',{key:option.id,staticClass:"\n          d-flex\n          justify-content-between\n          card_custom\n          pointer\n          align-items-center\n        ",on:{"click":function($event){return _vm.selectPaymentOption(option)}}},[_c('p',[_vm._v(_vm._s(option.label))]),_c('p',[_vm._v(" "+_vm._s(_vm.apiCost.data.prices[("" + (option.id))])+" ")])])}),0):_vm._e(),(_vm.paymentOption == 'creditcard')?_c('PaymentCC',{attrs:{"apiBody":_vm.apiBody},on:{"options":function ($event) {
          this$1.showPaymentOptions = $event;
        },"handleOldTransfer":_vm.handleOldTransfer}}):_vm._e()],1):_vm._e(),_c('Loading',{attrs:{"loading":_vm.loader}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }