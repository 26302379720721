<template>
  <div class="">
    <ControlNavbar/>
    <page-header :title="transfer.label"/>
    <div class="container">
      <div class="banner" v-if="transfer.banner">
        <Banner :banner="transfer.banner"/>
      </div>
      <br/>
      <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          @submit="handleSubmit"
          v-if="transfer"
      >
        <a-form-item
            :label="locale.country_code"
            v-if="transfer.data.countries"
        >
          <a-select
              v-decorator="[
              'countryCode',
              {
                initialValue: transfer.data.defaultCountry,
                rules: [
                  { required: true, messasge: locale.emptyFieldsWarning },
                ],
              },
            ]"
          >
            <a-select-option
                v-for="country in transfer.data.countries"
                :key="country"
                :value="country"
            >
              {{ countries[country.toUpperCase()] }}
              <img
                  :src="require(`../assets/flags/${country}.png`)"
                  width="35px"
                  alt=""
              />
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
            :label="transfer.data.hint"
            v-if="
            transfer.data.inputType &&
              (transfer.data.inputType == 'text' ||
                transfer.data.inputType == 'email')
          "
        >
          <a-input
              v-decorator="[
              'msisdn',
              {
                rules: [{ required: true, message: locale.emptyFieldsWarning }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
            :label="transfer.data.hint"
            v-else-if="
            transfer.data.inputType &&
              (transfer.data.inputType == 'phone' ||
                transfer.data.inputType == 'number' ||
                transfer.data.inputType == 'numeric' ||
                transfer.data.inputType == 'decimal')
          "
        >
          <a-input
              style="width: 100%"
              v-decorator="[
              `msisdn`,
              {
                rules: [
                  {
                    required: true,
                    message: locale.emptyFieldsWarning,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
            :label="transfer.data.amount.text"
            v-if="transfer.data.amount"
        >
          <a-input-number
              style="width: 100%"
              :min="transfer.data.amount.min"
              :max="transfer.data.amount.max"
              :step="transfer.data.amount.increment"
              :formatter="
              (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
              @change="
              (e) => {
                getTransferPrice(e);
              }
            "
              v-decorator="[
              `amount`,
              {
                rules: [
                  {
                    required: true,
                    message: `${transfer.data.amount.text} ${locale.must_be_between} ${transfer.data.amount.min} ${locale.and} ${transfer.data.amount.max} `,
                  },
                  {
                    type: 'number',
                    max: transfer.data.amount.max,
                    min: transfer.data.amount.min,
                    message: `${transfer.data.amount.text} ${locale.must_be_between} ${transfer.data.amount.min} ${locale.and} ${transfer.data.amount.max} `,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="transfer.data.cost" v-if="transfer.data.cost">
          <p id="price">
            {{ cost }}
          </p>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="danger" html-type="submit">
            {{ transfer.data.transfer }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-modal
        v-model="paymentOptionsVisible"
        :title="locale.paymentoptions"
        @ok="paymentOptionsVisible = !paymentOptionsVisible"
        :footer="null"
        v-if="apiCost"
    >
      <div v-if="showPaymentOptions">
        <div
            class="
            d-flex
            justify-content-between
            card_custom
            pointer
            align-items-center
          "
            v-for="option in $session.get('paymentOptions')"
            :key="option.id"
            @click="selectPaymentOption(option)"
        >
          <p>{{ option.label }}</p>
          <p>
            {{ apiCost.data.prices[`${option.id}`] }}
          </p>
        </div>
      </div>

      <PaymentCC
          v-if="paymentOption == 'creditcard'"
          @options="
          ($event) => {
            this.showPaymentOptions = $event;
          }
        "
          :apiBody="apiBody"
          @handleOldTransfer="handleOldTransfer"
      />
    </a-modal>
    <Loading :loading="loader"/>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
import Banner from "@/components/Banner";
import countriesCode from "../scripts/countries";
import PaymentCC from "@/components/PaymentCC";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";
import PageHeader from "./PageHeader.vue";

export default {
  name: "SpecificApplication",
  components: {ControlNavbar, Banner, PaymentCC, Loading, PageHeader},
  data() {
    return {
      locale: null,
      loader: false,
      countries: countriesCode.countriesCode,
      transfer: null,
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "applicationForm"}),
      cost: "",
      currentCost: null,

      // scoped needed vars for cc payment
      apiCost: null, //this var containes the cost response returned from backend( check where it's geting it's info and you'll understand)

      // credit card payment
      paymentOptionsVisible: false,
      paymentOption: null,
      apiBody: null,
      showPaymentOptions: true,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    setLoader(show) {
      this.loader = show;
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },

    // cc payment
    selectPaymentOption(payOption) {
      // when clicked on the price fields of payment options if credit card open credit card commponent and give it the values that it needs to send to the api
      if (payOption.type == "creditcard") {
        this.form.validateFields((err, values) => {
          if (!err) {
            values.type = this.transfer.type;
            values.fetchPrice = true;
            values.denominationId = this.transferId;
            // values.numberOfItems = values.Quantity;
            // delete values.Quantity;
            // this.checkPaymentMethod();
            // if (this.directRechargeField) {
            //   values.recipientMsisdn = this.directRechargeField;
            // }
            values.id = this.transfer.data.id;
            values.denominationId = this.transfer.data.id;
            values.optionId = payOption.id;
            values.countryCode = this.countries[
                `${values.countryCode.toUpperCase()}`
                ].replace("+", "");
            this.apiBody = values;
            this.apiBody.type = this.transfer.type;
            this.paymentOption = payOption.type;

            console.log("Received values of form: ", values);
          }
        });
      } else {
        this.paymentOptionsVisible = false;
        this.airtimeByWhish(payOption.id);
      }
    },
    isEmpty(obj) {
      for (var o in obj) {
        if (o) {
          return false;
        }
      }
      return true;
    },
    checkPaymentMethod() {
      // the whole cc payment is here just check payment solution and the modal will do the rest

      // always check prices in transfer object and server.paymentOptions
      // if both are not null
      // if topleft menu (default_payment exists) && usertogglevalue is true do api aade
      // else

      // get intersect between prices and server.paymentoptions (paymentoptions.id == apiCost.prices['id']) id is the key of the json
      // if empty do normal api
      //WHISHPAYMENT AND ANY OTHER else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected
      // CREDIT CARD else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected

      // if both or one of them null continue aade
      this.paymentOption = null;
      if (
          this.$session.get("paymentOptions") &&
          !this.isEmpty(this.apiCost.data.prices)
      ) {
        if (
            this.$session.get("userToggleValue") == true &&
            this.$session.get("default_payment") == true
        ) {
          console.log("do normal api");
          // do normal api
          return true;
        } else {
          console.log("methods");
          // check payments option
          this.paymentOptionsVisible = true;
          this.showPaymentOptions = true;
          return false;
        }
      } else {
        console.log("normal api");
        // do normal api
        return true;
      }
    },
    handleOldTransfer(values) {
      this.paymentOptionsVisible = false;
      this.setLoader(true);
      this.getAxios()
          .post(
              `${this.$session.get("baseURI")}sale/transfer/airtime`,
              //body
              values
          )
          .then((result) => {
            this.setLoader(false);
            console.log(result);
            if (this.checkApiRes(result, values, "sale/transfer/airtime", "post")) {
              // ok
              console.log("airtime success", result);
              this.success(result.data.dialog.title, result.data.dialog.message);

              // this.transferDetails = result.data.data.listOfSoldItems[0];
              // this.showModal();
            }
          });
    },

    // end cc payment

    error(title, message) {
      this.$error({
        title: title,
        content: message,
      });
    },
    success(title, message) {
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p>{message}</p>
            </div>
        ),
      });
    },
    getTransferPrice(amount) {
      console.log(amount);
      let him = this;
      setTimeout(function () {
        him.form.validateFields((err, values) => {
          if (!err) {
            values.id = him.transfer.data.id;
            values.countryCode = him.countries[
                `${values.countryCode.toUpperCase()}`
                ].replace("+", "");
            values.price = null;
            console.log("Received values of form: ", values);
            him.setLoader(true);
            him
                .getAxios()
                .post(`${him.$session.get("baseURI")}sale/transfer/cost`, values)
                .then((result) => {
                  him.setLoader(false);
                  if (him.checkApiRes(result, values, "sale/transfer/cost", "post")) {
                    // ok
                    console.log("ok get cost", result.data);
                    // put the price inside this.price to use it on submit
                    him.apiCost = result.data;
                    him.cost = result.data.data.price || "";
                    him.currentCost = result.data.data.currentPrice || null;
                  }
                });
          } else {
            him.cost = "";
            him.currentCost = null;
          }
        });
      }, 500);
    },
    airtimeByWhish(optionId) {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.optionId = optionId;
          values.id = this.transfer.data.id;
          values.countryCode = this.countries[
              `${values.countryCode.toUpperCase()}`
              ].replace("+", "");
          values.price = this.currentCost;
          console.log("Received values of form: ", values);
          this.setLoader(true);
          this.getAxios()
              .post(
                  `${this.$session.get("baseURI")}sale/transfer/airtime`,
                  //body
                  values
              )
              .then((result) => {
                this.setLoader(false);
                console.log(result);
                if (this.checkApiRes(result, values, "sale/transfer/airtime", "post")) {
                  // ok
                  console.log("airtime success", result);
                  this.success(
                      result.data.dialog.title,
                      result.data.dialog.message
                  );

                  // this.transferDetails = result.data.data.listOfSoldItems[0];
                  // this.showModal();
                }
              });
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id = this.transfer.data.id;
          values.countryCode = this.countries[
              `${values.countryCode.toUpperCase()}`
              ].replace("+", "");
          values.price = this.currentCost;
          console.log("Received values of form: ", values);
          if (this.checkPaymentMethod()) {
            this.setLoader(true);
            this.getAxios()
                .post(
                    `${this.$session.get("baseURI")}sale/transfer/airtime`,
                    //body
                    values
                )
                .then((result) => {
                  this.setLoader(false);
                  console.log(result);
                  if (this.checkApiRes(result, values, "sale/transfer/airtime", "post")) {
                    // ok
                    console.log("airtime success", result);
                    this.success(
                        result.data.dialog.title,
                        result.data.dialog.message
                    );

                    // this.transferDetails = result.data.data.listOfSoldItems[0];
                    // this.showModal();
                  }
                });
          }
        }
      });
    },
  },
  computed: {},
  created() {
    this.checkLocal();
    if (this.$route.params.old) {
      let values = this.$route.params.old;
      this.handleOldTransfer(values);
    }
    console.log(
        "transfer: ",
        this.$route.params.transfer || this.$session.get("backup")
    );
    if (!this.$route.params.transfer) {
      //  this.$route.params.transfer;
      this.transfer = this.$session.get("backup");
    } else {
      this.transfer = this.$route.params.transfer;
      this.$session.set("backup", this.$route.params.transfer);
    }
  },
  beforeCreate: function () {
    // let tempApp = this.$route.params.transfer;
    // let tempOrdered = [];
    // tempApp.data.fields.forEach((element) => {
    //   tempOrdered[element.index / 1000 - 1] = element;
    // });
    // this.$route.params.transfer.data.fields = tempOrdered;
  },
};
</script>

<style lang="scss" scoped>
.card_custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  padding: 2%;
  height: 70px;
  margin-bottom: 10px;
}

.card_custom:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
</style>
